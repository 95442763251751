import React from 'react'
import Nav from './Nav/Nav'

const Header = () => {
  return  <div>
      <Nav/>
    </div>;
  
}

export default Header